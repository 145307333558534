<div class="app-modal-content appsolution-dialog">
  <div class="appsolution-dialog__image">
    <img [src]="'/assets/analytics/images/yellow-warning.png'" alt="Warning" />
  </div>
  <div class="appsolution-dialog__title">Proceed with caution</div>
  <div class="appsolution-dialog__message">
      <h5>{{ message }}</h5>
    <p>To provide user data select a delivery method</p>

    <div class="dropdown-wrapper">
      <p>Delivery Method</p>
      <app-select-input
        [customClass]="'appsolution-dropdown'"
        [mini]="true"
        [(ngModel)]="selectedOption"
        [options]="options"
        [showField]="'label'"
        [valueField]="'value'"
      ></app-select-input>
    </div>

      <div class="checkbox-url" *ngIf="selectedOption === registrationType.APP_SOLUTION">
          <app-checkbox
              [(ngModel)]="isUrlSelected"
              (ngModelChange)="checkTags()"
              name="forwardUserId"
              [labelText]="'Identify players based on userId' | translate">
          </app-checkbox>
      </div>

    <div class="appsolution-info" *ngIf="selectedOption === registrationType.APP_SOLUTION">
      Using the JavaScript App interface will prevent unknown users from accessing your campaign and allow you to connect campaign usage data to your users.
      <a class="appsolution-info__action" [href]="'mailto:support@brame.ch'" target="_blank">
        <svg-icon src="assets/page-selector/default-email.svg"></svg-icon>
        <span>Contact support</span>
      </a>
    </div>

      <div class="checkbox-url" *ngIf="selectedOption === registrationType.URL_PARAMETERS">
          <app-checkbox
              [(ngModel)]="isUrlSelected"
              (ngModelChange)="checkTags()"
              name="forwardUserId"
              [labelText]="'Identify players based on userId' | translate">
          </app-checkbox>
      </div>

    <div class="appsolution-info" *ngIf="selectedOption === registrationType.URL_PARAMETERS">
      Using the URL parameters will prevent unknown users from accessing your campaign and allow you to connect campaign usage data to your users.
      <div class="appsolution-info__action" (click)="toggleLearnMore()">
        <svg-icon src="assets/shared/svg/lightbulb.svg"></svg-icon>
        <span>Learn more</span>
      </div>
    </div>

  </div>
</div>

<div class="app-modal-footer">
  <app-button
    text="Cancel"
    [styles]="'light'"
    (output)="onCancelClick()"
  ></app-button>
  <app-button
    text="Continue"
    [styles]="'light'"
    [isDisabled]="selectedOption === options[0].value"
    (output)="onConfirmClick()"
  ></app-button>
</div>

<div class="appsolution-learn-more" *ngIf="isLearnMoreVisible">
  <div class="appsolution-learn-more__close" (click)="toggleLearnMore()">
    <svg-icon src="assets/shared/svg/x-2.svg"></svg-icon>
  </div>

  <div class="appsolution-learn-more__icon">
    <svg-icon src="assets/shared/svg/lightbulb.svg"></svg-icon>
  </div>
  <div class="appsolution-learn-more__text">
    <p>Automatically register players using a custom URL format for exclusive campaign access.</p>

    <span>Required Information:</span>
    <ul>
      <li>userId</li>
      <li>email</li>
    </ul>

    <span>Optional Information:</span>
    <ul>
      <li>firstName</li>
      <li>lastName</li>
      <li>phone</li>
      <li>address</li>
      <li>postalCode</li>
      <li>city</li>
      <li>birthDate</li>
      <li>jobTitle</li>
      <li>company</li>
    </ul>

    <span> Birth date accepts values as:</span>
    <ul>
      <li> mm/dd/yyyy</li>
      <li>mm-dd-yyyy</li>
      <li>mm.dd.yyyy</li>
    </ul>

    <span>Example link:</span>
    <p class="appsolution-learn-more__link">
      https://live-app.brame-gamification.com/yourCampaignID/?
      lang=de&firstName=John&lastName=Doe&
      email=john_doe@yahoo.com&
      city=Paris&company=TestCo&birthDate=12.22.1990
    </p>
    <p></p>
  </div>

</div>