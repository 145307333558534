import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { ResponseDTO } from "@models/ResponseDTO";
import { Outcome } from "@models/game-data/Outcome";
import { CampaignType } from "@models/CampaignTypes";
import { MemoryGameData, MemoryOutcome } from "@models/game-data/MemoryGameData";
import { QuizGameData, QuizOutcome } from "@models/game-data/QuizGameData";

@Injectable({
    providedIn: "root",
})
export class OutcomeService {
    apiPrefix: string = environment.apiUrlPrefix + "/outcome/";

    constructor(private http: HttpClient) {}

    // BASE METHODS AND UTILITIES
    getAllByCampaignId(campaignId: string, campaignType: CampaignType, language = ""): Observable<Outcome[]> {
        if (campaignType === CampaignType.MEMORY) {
            return this.getMemoryOutcomes(campaignId);
        }

        const endpoint = `${this.resolveOutcomesEndpoint(campaignType)}/${campaignId}`;
        return this.http.get(endpoint, { params: this.createLanguageQueryParams(language) })
            .pipe(map((response: ResponseDTO) => response.data as Outcome[]));
    }

    update(campaignId: string, campaignType: CampaignType, outcome: Outcome) {
        const isSurvey = campaignType === CampaignType.SURVEY;

        const endpoint = isSurvey
            ? environment.surveyApiUrlPrefix + "/outcome/"
            : this.apiPrefix;
        const data = isSurvey ? { campaignId, ...outcome } : outcome;

        return this.http.put(endpoint, data).pipe(map((response: ResponseDTO) => response.data));
    }

    private createLanguageQueryParams(language: string | undefined): HttpParams {
        return language ? new HttpParams().append("language", language) : new HttpParams();
    }

    private resolveOutcomesEndpoint(campaignType: CampaignType): string {
        const endpointsMap = {
            [CampaignType.SURVEY]: `${environment.surveyApiUrlPrefix}/outcome/getAll`,
            [CampaignType.SCRATCH_CARD]: `${environment.scratchCardApiBaseUrl}/outcome/getAll`,
            [CampaignType.QUIZ]: `${environment.quizApiBaseUrl}/game-settings/outcomes`,
            [CampaignType.ADVENT_CALENDAR]: `${environment.serverlessApiBaseUrl}/calendar-game/outcomes`,
            [CampaignType.MONTHLY_CALENDAR]: `${environment.serverlessApiBaseUrl}/calendar-game/outcomes`,
            [CampaignType.EASTER_CALENDAR]: `${environment.serverlessApiBaseUrl}/calendar-game/outcomes`,
            [CampaignType.WEEKLY_CALENDAR]: `${environment.serverlessApiBaseUrl}/calendar-game/outcomes`,
        };
        return endpointsMap[campaignType] || `${this.apiPrefix}getAll`;
    }

    private parseCampaignType(type: CampaignType): string {
        return type.includes("calendar") ? "calendar-game" : type;
    }

    // MEMORY-RELATED METHODS AND UTILITIES
    private getMemoryOutcomes(campaignId: string) {
        const url = `${environment.memoryApiBaseUrl}/game-settings/${campaignId}`;
        return this.http.get<MemoryGameData>(url).pipe(
            map((gameSettings) => gameSettings.outcomes),
            map((outcomes) => {
                const collection: Outcome[] = [];
                outcomes.forEach((o) => {
                    collection.push(...o.outcomes);
                });
                return collection;
            })
        );
    }

    private getSortedMemoryOutcomes = (campaignId: string) => {
        const url = `${environment.memoryApiBaseUrl}/game-settings/${campaignId}`;
        return this.http.get<MemoryGameData>(url).pipe(
            map((data) => this.sortMemoryOutcomesByDownTime(data)),
            map((sortedOutcomes) => this.flattenMemoryOutcomes(sortedOutcomes))
        );
    };

    private sortMemoryOutcomesByDownTime = (gameSettings: MemoryGameData): MemoryOutcome[] =>
        [...gameSettings.outcomes].sort((o1, o2) => o1.downTimeThreshold - o2.downTimeThreshold);

    private flattenMemoryOutcomes = (sortedOutcomes: MemoryOutcome[]): Outcome[] => sortedOutcomes.flatMap((o) => o.outcomes);

    private updateMemoryOutcomes(campaignId: string, outcomes: Outcome[]) {
        const url = `${environment.memoryApiBaseUrl}/game-settings/${campaignId}`;
        return this.http.get<MemoryGameData>(url).pipe(
            switchMap((gameSettings) => {
                const updatedGameSettings = this.updateMemoryOutcomesInGameSettings(gameSettings, outcomes);
                return this.http.patch<MemoryGameData>(url, updatedGameSettings);
            })
        );
    }

    private updateMemoryOutcomesInGameSettings(gameSettings: MemoryGameData, outcomes: Outcome[]): MemoryGameData {
        return {
            ...gameSettings,
            outcomes: gameSettings.outcomes.map((mo) => ({
                ...mo,
                outcomes: mo.outcomes.map((outcome) => {
                    const updated = outcomes.find((o) => o.id === outcome.id);
                    return updated ? { ...outcome, ...updated } : outcome;
                }),
            })),
        };
    }

    // QUIZ-RELATED METHODS AND UTILITIES
    private updateQuizOutcomes(campaignId: string, outcomes: Outcome[]): Observable<ResponseDTO> {
        const url = this.getQuizGameSettingsUrl(campaignId);
        return this.http.get(url).pipe(
            switchMap((gameSettings: any) => {
                gameSettings.outcomes.forEach((gameOutcome) => {
                    gameOutcome.outcomes = this.mergeQuizOutcomes(gameOutcome.outcomes, outcomes);
                });
                return this.http.put<ResponseDTO>(url, gameSettings);
            })
        );
    }

    private getQuizGameSettingsUrl(campaignId: string): string {
        return `${environment.quizApiBaseUrl}/game-settings/${campaignId}`;
    }

    private mergeQuizOutcomes(originalOutcomes: Outcome[], updatedOutcomes: Outcome[]): Outcome[] {
        return originalOutcomes.map((originalOutcome) => {
            const matchedOutcome = updatedOutcomes.find((updated) => updated.id === originalOutcome.id);
            return matchedOutcome ? { ...originalOutcome, ...matchedOutcome } : originalOutcome;
        });
    }

    private fetchQuizSettings(campaignId: string): Observable<QuizGameData> {
        const url = `${environment.quizApiBaseUrl}/game-settings/${campaignId}`;
        return this.http.get<QuizGameData>(url);
    }

    private sortQuizOutcomesByDownThreshold(outcomes: QuizOutcome[]): QuizOutcome[] {
        return [...outcomes].sort((o1, o2) => o1.downThreshold - o2.downThreshold);
    }

    private flattenQuizOutcomes(outcomes: QuizOutcome[]): Outcome[] {
        const collection: Outcome[] = [];
        outcomes.forEach((o) => collection.push(...o.outcomes));
        return collection;
    }

    private getQuizOutcomesSorted(campaignId: string): Observable<Outcome[]> {
        return this.fetchQuizSettings(campaignId).pipe(
            map((gameSettings) => gameSettings.outcomes),
            map((outcomes) => this.sortQuizOutcomesByDownThreshold(outcomes)),
            map((sortedOutcomes) => this.flattenQuizOutcomes(sortedOutcomes))
        );
    }

    // SCRATCH CARD-RELATED METHODS AND UTILITIES
    private getSortedScratchOutcomes(campaignId: string) {
        const url = `${environment.scratchCardApiBaseUrl}/outcome/getAll/${campaignId}`;
        return this.http.get<ResponseDTO>(url).pipe(map(({ data }) => data));
    }

    private mergeScratchOutcomes(existingOutcomes: Outcome[], newOutcomes: Outcome[]): Outcome[] {
        return existingOutcomes.map((outcome) => {
            const match = newOutcomes.find((_o) => _o.id === outcome.id);
            return match ? { ...outcome, ...match } : outcome;
        });
    }

    private fetchScratchGameSettings(campaignId: string): Observable<any> {
        const url = `${environment.scratchCardApiBaseUrl}/game-settings/${campaignId}`;
        return this.http.get(url).pipe(
            map((response: ResponseDTO) => response.data)
        );
    }

    private updateScratchGameSettings(gameSettings: any): Observable<any> {
        return this.http.put<ResponseDTO>(
            `${environment.scratchCardApiBaseUrl}/game-settings`, gameSettings
        ).pipe(
            map(({ data }) => data)
        );
    }

    private updateScratchCardOutcomes(campaignId: string, outcomes: Outcome[]): Observable<any> {
        return this.fetchScratchGameSettings(campaignId).pipe(
            switchMap((gameSettings: any) => {
                gameSettings.outcomes.forEach((o) => {
                    o.outcomes = this.mergeScratchOutcomes(o.outcomes, outcomes);
                });

                return this.updateScratchGameSettings(gameSettings);
            })
        );
    }

    // All outcomes
    getAllOutcomesSorted(campaignId: string, campaignType: CampaignType) {
        switch (campaignType) {
        case CampaignType.MEMORY:
            return this.getSortedMemoryOutcomes(campaignId);
        case CampaignType.SCRATCH_CARD:
            return this.getSortedScratchOutcomes(campaignId);
        case CampaignType.QUIZ:
            return this.getQuizOutcomesSorted(campaignId);
        case CampaignType.ADVENT_CALENDAR:
        case CampaignType.MONTHLY_CALENDAR:
        case CampaignType.WEEKLY_CALENDAR:
        case CampaignType.EASTER_CALENDAR:
            return this.http.get(`${environment.serverlessApiBaseUrl}/calendar-game/outcomes_sorted`, {
                params: new HttpParams().set("campaign_id", campaignId),
            });
        default:
            const url = `${
                environment.apiUrlPrefix
            }/${this.parseCampaignType(campaignType)}/outcomes_sorted`;
            return this.http.get(url, {
                params: new HttpParams().set("campaign_id", campaignId),
            });
        }
    }

    updateAllOutcomes(campaignId: string, campaignType: CampaignType, outcomes: Outcome[]): Observable<any> {
        switch (campaignType) {
        case CampaignType.MEMORY:
            return this.updateMemoryOutcomes(campaignId, outcomes);
        case CampaignType.SCRATCH_CARD:
            return this.updateScratchCardOutcomes(campaignId, outcomes);
        case CampaignType.QUIZ:
            return this.updateQuizOutcomes(campaignId, outcomes);
        default:
            const endpoint = `${environment.surveyApiUrlPrefix}/outcome/`;
            const data = { campaignId, outcomes };
            return this.http
                .put(endpoint, data)
                .pipe(map((response: ResponseDTO) => response.data));
        }
    }
}
