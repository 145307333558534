export const analyticEvents = {
    appInit: "App Init",
    userLoggedIn: "User Logged In",
    loginFailed: "Login Failed",
    userLoggedOut: "User Logged Out",
    dashboardOpened: "Dashboard Opened",
    searchTriggered: "Search Triggered",
    searchReturned: "Search Returned",
    newCampaignStarted: "New Campaign Started",
    campaignPublished: "Campaign Published",
    campaignUpdated: "Campaign Updated",
    gamesLibraryOpened: "Games Library Opened",
    campaignDeleted: "Campaign Deleted",
    campaignDuplicated: "Campaign Duplicated",
    analyticsOpened: "Analytics Opened",
    campaignOpened: "Campaign Opened",
    campaignStopped: "Campaign Stopped",
    cookiesDisplayed: "Cookies Displayed",
    cookiesAccepted: "Cookies Accepted",
    analyticsReset: "Analytics Reset",
    analyticsLeadsRemoval: "Analytics Leads Removal",
    analyticsLeadsExport: "Analytics Leads Export",
};

