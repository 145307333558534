import { Component, EventEmitter, Input, Output } from "@angular/core";
import { RegistrationType } from "@builder-models/RegistrationType";
import { Service } from "@builder-services/core/service";
import { BuilderStateService } from "@builder-services/core/builder-state.service";
import { ModalService } from "../../../../../../shared/modal/modal.service";

@Component({
    selector: "appsolution-confirm-dialog",
    templateUrl: "appsolution-confirm-dialog.component.html",
    styleUrls: ["appsolution-confirm-dialog.component.scss"],
})

export class AppsolutionConfirmDialogComponent {
    @Input() data: string;
    @Input() emitOnCancel = true;
    @Input() title = "Warning";
    @Input() excludeNoDelivery = false;
    @Input() message = "You are about to turn off the registration page!";
    @Output() output: EventEmitter<string> = new EventEmitter();

    registrationType = RegistrationType;
    options = [
        { value: RegistrationType.DEFAULT, label: "Please choose delivery method", disabled: true },
        { value: RegistrationType.NO_DELIVERY, label: "I will not provide user data" },
        { value: RegistrationType.APP_SOLUTION, label: "JavaScript App interface" },
        { value: RegistrationType.URL_PARAMETERS, label: "URL parameters" },
    ];
    selectedOption = this.options[0].value;
    isLearnMoreVisible = false;
    isUrlSelected = false;
    isASTW: boolean;

    static readonly dialogName: string = "appsolution-dialog";

    constructor( private modalService: ModalService,
                 private service: Service,
                 private stateService: BuilderStateService,
    ) {
    }

    ngOnInit() {
        this.isASTW = this.service.config.campaignType === "advanced-spin-the-wheel";
        this.setOptions();
    }

    private setOptions() {
        this.options = [
            { value: RegistrationType.DEFAULT, label: "Please choose delivery method", disabled: true },
            { value: RegistrationType.NO_DELIVERY, label: "I will not provide user data" },
            { value: RegistrationType.APP_SOLUTION, label: "JavaScript App interface" },
            { value: RegistrationType.URL_PARAMETERS, label: "URL parameters" },
        ];

        if (this.excludeNoDelivery || this.isASTW) {
            this.options = this.options.filter((option) => option.value !== RegistrationType.NO_DELIVERY);
        }

        this.selectedOption = this.options[0].value;
    }

    onCancelClick() {
        if (this.emitOnCancel) {
            this.output.emit(null);
        }
        this.modalService.close(AppsolutionConfirmDialogComponent.dialogName);
    }

    onConfirmClick() {
        this.modalService.close(AppsolutionConfirmDialogComponent.dialogName);
        this.output.emit(this.selectedOption);
    }

    toggleLearnMore() {
        this.isLearnMoreVisible = !this.isLearnMoreVisible;
    }

    checkTags() {
        if (this.isUrlSelected) {
            if (!this.service.metadata.tags.includes("usesClientUserId")) {
                this.service.metadata.tags.push("usesClientUserId");
            }
        } else {
            this.service.metadata.tags = this.service.metadata.tags.filter((tag) => tag !== "usesClientUserId");
        }
        this.stateService.$metadataModified.next(true);
    }

}
