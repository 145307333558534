import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";
import { AdvancedSpinTheWheelService } from "@services/game-data/advanced-spin-the-wheel.service";
import { HttpErrorResponse } from "@angular/common/http";
import { AdvancedSpinTheWheelGameData } from "@models/game-data/AdvancedSpinTheWheelGameData";
import { Service } from "@builder-services/core/service";
import { RegistrationType } from "@builder-models/RegistrationType";
import { CampaignType } from "@models/CampaignTypes";
import { CampaignDataService } from "@services/campaign-data/campaign-data.service";
import { BuilderDataConfig } from "@builder-models/BuilderDataConfig";
import { PageType } from "@builder-models/Page";
import { BuilderStateService } from "@builder-services/core/builder-state.service";
import { Observable } from "rxjs";
import {
    AppsolutionConfirmDialogComponent,
} from "../../../components/builder-sidemenu/pages/appsolution-confirm-dialog/appsolution-confirm-dialog.component";
import { ModalService } from "../../../../../shared/modal/modal.service";
import { UtilService } from "../../../../../core/util-services/util.service";

@Injectable()
export class RegistrationFlowService {
    constructor(
        private service: Service,
        private modalService: ModalService,
        private state: BuilderStateService,
        private advancedSpinTheWheelService: AdvancedSpinTheWheelService,
        private campaignDataService: CampaignDataService,
        private utils: UtilService,
    ) {
    }

    updateRegistrationType(registrationType: RegistrationType, campaignType: CampaignType) {
        let config = this.service.config;

        if (this.utils.deliveryMethodGames(campaignType)) {
            config = {
                ...this.service.config,
                registrationType,
            };

            this.updateCampaignConfig(config).subscribe({
                next: (response) => {
                    this.service.config = { ...this.service.config, ...response };
                },
            });
            return;
        }

        switch (campaignType) {
        case CampaignType.ADVANCED_SPIN_THE_WHEEL:
            const gameData = {
                ...this.service.gameData,
                registrationType,
            };
            this.updateAdvancedSpinTheWheelData(gameData).subscribe({
                next: (response) => {
                    this.service.gameData = { ...this.service.gameData, ...response };
                    this.service.config = { ...this.service.config, ...response };
                },
            });
            break;
        }
    }

    updateServiceConfigPage(key: string, isIncluded: boolean) {
        const pageIndex = this.service.config.pages.findIndex((page) => page.key === key);
        this.service.config.pages[pageIndex].included = isIncluded;

        const includedPages = this.service.config.pages.filter(
            (page) => (!page.type || page.type === PageType.PAGE) && page.included !== false
        );

        for (let i = 0; i < includedPages.length; i++) {
            if (i < includedPages.length - 1) {
                includedPages[i].nextPage = includedPages[i + 1].key;
            } else {
                includedPages[i].nextPage = null;
            }
        }

        this.state.$configurationModified.next(true);
    }

    updatePrizeEmails(disablePrizeRedemptionEmails: boolean) {
        const gameData: AdvancedSpinTheWheelGameData = {
            ...this.service.gameData,
            disablePrizeRedemptionEmails,
        };

        if(this.service.config.campaignType === CampaignType.ADVANCED_SPIN_THE_WHEEL) {
            this.updateAdvancedSpinTheWheelData(gameData).subscribe({
                next: (response) => {
                    this.service.gameData = { ...this.service.gameData, ...response };
                },
            });
        }
    }

    private updateAdvancedSpinTheWheelData(gameData: AdvancedSpinTheWheelGameData) {
        return this.advancedSpinTheWheelService.updateGameData(gameData).pipe(
            catchError(this.handleError.bind(this))
        );
    }

    private updateCampaignConfig(config: BuilderDataConfig) {
        return  this.campaignDataService.putCampaignConfiguration(
            config.campaignId,
            config
        );
    }

    private handleError(e: Error) {
        if (e instanceof HttpErrorResponse && e.status === 422) {
            console.error(e.error);
        }

        throw e;
    }

    openDeliveryMethodModal(campaignType: CampaignType): Observable<RegistrationType> {
        if (this.shouldOpenModal() && this.utils.deliveryMethodGames(campaignType)) {
            return this.modalService.open(
                AppsolutionConfirmDialogComponent.dialogName,
                AppsolutionConfirmDialogComponent,
                "md",
                null,
                "",
                false,
                "",
                "",
                false,
                {
                    inputs: {
                        message: "You're about to enable the Earning points integration",
                        excludeNoDelivery: true,
                        emitOnCancel: false,
                    },
                }
            );
        }
    }

    private shouldOpenModal(): boolean {
        const { registrationType } = this.service.config;
        return registrationType !== RegistrationType.URL_PARAMETERS &&
            registrationType !== RegistrationType.APP_SOLUTION;
    }
}
