import { Injectable } from "@angular/core";

@Injectable()
export class LocalStorageService {

    getItem(key: string, fallback?: any[]) {
        const x = localStorage.getItem(key);

        if (x === null && fallback !== undefined) {
            return fallback;
        } else if (x === null) {
            throw new Error(`LocalStorageService >> no item with key > ${key}`);
        }

        return JSON.parse(x);
    }

    setItem(key: string, item: any) {
        localStorage.setItem(key, JSON.stringify(item));
    }

    removeItem(key: string) {
        localStorage.removeItem(key);
    }

    exists(key: string) {
        const x = localStorage.getItem(key);
        return x != null;
    }

}
